import React, { Component } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { ScrollingProvider } from 'react-scroll-section';
import config from 'react-reveal/globals';
import colors from '../../colors';
import Helmet from './Helmet';

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before { 
    box-sizing: inherit;
    }

  body {
    box-sizing: border-box; 
    margin: 0;
    font-family: Cabin, 'Open Sans', sans-serif;
    font-display: swap;
    font-display: fallback;
    overflow-x: hidden;
  }
`;

config({ ssrFadeout: true });

const locales = {
  'es-CL': 'ES',
  'en-US': 'EN'
}

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locale: 'en-US'
    }
  }

  componentDidMount() {
    this.loadScript('https://use.fontawesome.com/fd58d214b9.js');
    const locale = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale'): 'en-US'
    this.setState({
      locale
    })
  }

  loadScript = src => {
    const tag = document.createElement('script');
    tag.src = src;
    tag.defer = true;

    document.getElementsByTagName('body')[0].appendChild(tag);
  };

  saveLocale = (value) => {
    window.sessionStorage.setItem('locale', value);
  }
  changeLocale = () => {
    if(this.state.locale === 'en-US') {
      this.setState({locale: 'es-CL'}, () => this.saveLocale('es-CL'))
    }
    if(this.state.locale === 'es-CL') {
      this.setState({locale: 'en-US'}, () => this.saveLocale('en-US'))
    }
  }
  getLocal = () => {
    if(this.state.locale === 'en-US') {
      return locales['es-CL']
    }
    if(this.state.locale === 'es-CL') {
      return locales['en-US']
    }
  }

  render() {
    const clone = React.Children.map(
      this.props.children, 
      (el, index) => React.cloneElement(el, {key: index, changeLocale: this.changeLocale, local: this.getLocal()})
    )
    return (
      <main>
        <GlobalStyle/>
        <ThemeProvider theme={{colors}}>
          <ScrollingProvider>
            <Helmet/>
            {clone}
          </ScrollingProvider>
        </ThemeProvider>
      </main>
    );
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
