module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#49B3BC',

  primary: '#114B5F',
  primaryLight: '#517C8A',
  primaryDark: '#0B303D',

  secondary: '#F3D84B',
  secondaryLight: '#F7E68E',
  secondaryDark: '#EFCA08',
};
